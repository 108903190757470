import { Auth0Config } from '../auth0';
import { Environments } from './environments';

export class AppConfiguration {
    instance: string;
    environment: Environments;
    auth0: Auth0Config;
    api: {
        applicationUrl: string;
        authorization: string;
        download: string;
        signUp: string;
        codmanUrl: string;
    };
}
