import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiType, ApplicationGroup } from '../types';
import { map } from 'rxjs/operators';
import { AvailableFile, DownloadLinkProperties as TokenResult } from '../types/files-types';
import { AccountDetail, JobTitleCategory } from 'app/authorized/edit-user/edit-user.types';
@Injectable({ providedIn: 'root' })
export class ApplicationGateway {
    constructor(private _httpClient: HttpClient) {}

    getApplications(organizationId: number): Observable<ApplicationGroup[]> {
        let params: HttpParams = new HttpParams();
        if (organizationId) {
            params = params.set('organizationId', organizationId);
        }
        return this._httpClient
            .get<{ applicationGroups: ApplicationGroup[] }>(`/api/Applications/get`, {
                params
            })
            .pipe(
                map(res => res.applicationGroups || []),
                map(applicationGroups => {
                    applicationGroups.forEach(group => {
                        group.applications.forEach(app => (app.groupCode = group.code));
                    });
                    return applicationGroups;
                })
            );
    }

    getFiles(organizationId: number): Observable<AvailableFile[]> {
        let params: HttpParams = new HttpParams();
        if (organizationId) {
            params = params.set('organizationId', organizationId);
        }
        return this._httpClient.get<AvailableFile[]>(`/api/v1.0/files/available`, {
            headers: { type: ApiType.Download },
            params
        });
    }

    getDownloadLink(guid: string): Observable<TokenResult> {
        return this._httpClient.post<TokenResult>(
            `/api/v1.0/files/${guid}/links`,
            {},
            {
                headers: { type: ApiType.Download }
            }
        );
    }

    getUserInfo(email: string, organizationId: number): Observable<AccountDetail> {
        return this._httpClient.get<AccountDetail>(`api/account/${email}/${organizationId}`);
    }

    updateUserInfo(email: string, accountDetails: AccountDetail): Observable<void> {
        return this._httpClient.put<void>(`api/account/${email}`, accountDetails);
    }

    getJobTitleCategories(): Observable<JobTitleCategory[]> {
        return this._httpClient.get<JobTitleCategory[]>(`api/job-title-category`);
    }
}
