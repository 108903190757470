import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    LgLocalizationModule,
    LG_LOCALIZATION_SETTINGS,
    ReferenceTranslateCompiler,
    useMessageFormatLocales
} from '@logex/framework/lg-localization';
import {
    AppLocalizationSettings,
    AppPermisions,
    AppStartupService,
    AppUser,
    Auth0InterceptorService,
    AUTH0_CONFIG,
    AuthorizationAppConfiguration,
    AUTHORIZATION_APP_CONFIGURATION,
    ConfigService,
    Language
} from './shared';
import { LgApplicationPermissionsBase, LG_USER_INFO } from '@logex/framework/lg-application';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { LgConsoleConfiguration } from '@logex/framework/core';
import { SharedModule } from './shared/shared.module';
import { UiCoreModule } from '@logex/framework/ui-core';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler }
        }),
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: 'localization'
        }),
        UiCoreModule,
        AppRoutingModule,
        SharedModule
    ],
    providers: [
        {
            provide: LgConsoleConfiguration,
            useFactory: () => {
                const config = new LgConsoleConfiguration();
                return config;
            }
        },
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: AppLocalizationSettings
        },
        useMessageFormatLocales(['nl', 'nl-NL', 'en', 'en-GB']),
        {
            provide: LOCALE_ID,
            useValue: Language.NL
        },
        {
            provide: LG_USER_INFO,
            useValue: new AppUser()
        },
        {
            provide: LgApplicationPermissionsBase,
            useExisting: AppPermisions
        },
        {
            provide: AUTHORIZATION_APP_CONFIGURATION,
            useValue: new AuthorizationAppConfiguration()
        },
        {
            provide: ConfigService,
            useClass: ConfigService
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: AppStartupService) => startup.initialize(),
            deps: [AppStartupService],
            multi: true
        },
        {
            provide: AUTH0_CONFIG,
            useFactory: (startup: ConfigService) =>
                startup.configurationPromise.then(config => config.auth0),
            deps: [ConfigService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0InterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
