import { Inject, LOCALE_ID, Injectable } from '@angular/core';
import { LgLocalizationSettings } from '@logex/framework/lg-localization';
import { Dictionary } from 'lodash';
import { LgLoaderService } from '@logex/framework/lg-layout';
import { LocalizationGateway } from '../gateways';
import { Observable } from 'rxjs';
import { AppUser } from '../types/app-user';
import { LG_USER_INFO } from '@logex/framework/lg-application';
import { LgConfirmationDialog } from '@logex/framework/ui-core';

export enum Language {
    NL = 'nl-NL',
    EN = 'en-GB'
}

@Injectable()
export class AppLocalizationSettings implements LgLocalizationSettings {
    public bootstrapDone: Promise<void>;

    readonly languages: _.Dictionary<any[]> = {};

    private _preferredLanguage;
    private setReady: () => void;

    constructor(
        @Inject(LOCALE_ID) private _locale: string,
        @Inject(LG_USER_INFO) private _userInfo: AppUser,
        private _lgLoaderService: LgLoaderService,
        private _localizationGateway: LocalizationGateway,
        private _confirm: LgConfirmationDialog
    ) {
        this.bootstrapDone = new Promise(resolve => {
            this.setReady = resolve;
        });
    }

    get fallbackLanguage(): Language {
        return Language.EN;
    }

    get preferredLanguage(): string {
        return this._preferredLanguage;
    }

    get availableLanguages(): string[] {
        return [Language.NL, Language.EN];
    }

    get locale(): string {
        return this.preferredLanguage;
    }

    get currency(): string {
        return 'EUR';
    }

    get userLanguage(): Language {
        return this._userInfo.locale;
    }

    init(): void {
        this._initUserLanguage();
        this.setReady();
    }

    setStrings(lang: Language, strings: Dictionary<any>): void {
        this.languages[lang] = [strings];
    }

    setPreferredLanguage(lang: Language): Observable<Language> {
        this._preferredLanguage = lang;
        return this._localizationGateway.setPrefferedLanguage(lang);
    }

    addStrings(lang: string, strings: _.Dictionary<any>): void {
        if (!this.languages[lang]) this.languages[lang] = [];
        this.languages[lang].push(strings);
    }

    switchCurrentLanguage(lang: Language): void {
        if (this._preferredLanguage === lang) return;

        this._lgLoaderService.show();
        this.setPreferredLanguage(lang).subscribe({
            next: () => {
                window.location.reload();
            },
            error: () => {
                this._lgLoaderService.hide();
                this._confirm.alertLC(
                    'APP.LanguageSwitchError.Title',
                    'APP.LanguageSwitchError.Text'
                );
            }
        });
    }

    private _initUserLanguage(): void {
        if (this.userLanguage) {
            if (this.availableLanguages.includes(this.userLanguage)) {
                this._preferredLanguage = this.userLanguage;
                return;
            }
        }
        this._preferredLanguage = this._locale;
    }
}
