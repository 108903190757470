import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Auth0Guard } from './shared';

const routes: Routes = [
    {
        path: 'callback',
        redirectTo: 'organizations'
    },
    {
        path: 'unauthorized',
        loadChildren: () =>
            import('./unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)
    },
    {
        path: '',
        loadChildren: () => import('./authorized/authorized.module').then(m => m.AuthorizedModule),
        canLoad: [Auth0Guard],
        canActivate: [Auth0Guard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
