import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { ApiType, AppUser, AuthorizationPermission } from '../types';
import { filter, tap } from 'rxjs/operators';
import { AppPermisions } from '../app-permissions';
import { LG_USER_INFO } from '@logex/framework/lg-application';
import { Language } from './app-localization-settings.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
    public _permissions: string[] = null;
    public _permissionsSubject = new BehaviorSubject<string[]>(null);
    public _permissions$ = this._permissionsSubject.pipe(filter(val => val !== null));

    constructor(
        private _httpClient: HttpClient,
        private _configService: ConfigService,
        @Inject(LG_USER_INFO) private _userInfo: AppUser
    ) {}

    loadUserData(): Observable<{
        user: AppUser;
        permissions: AuthorizationPermission[];
    }> {
        return forkJoin({
            user: this._getUserProfile(),
            permissions: this._getPermissions()
        });
    }

    private _getUserProfile(): Observable<AppUser> {
        return this._httpClient
            .get<AppUser>(`/user/profile`, {
                headers: { type: ApiType.Authorization }
            })
            .pipe(tap((user: AppUser) => this._setUser(user)));
    }

    private _getPermissions(): Observable<AuthorizationPermission[]> {
        return this._httpClient
            .post<AuthorizationPermission[]>(
                `/user/permissions/search`,
                { applicationInstances: [this._configService.configuration.instance] },
                { headers: { type: ApiType.Authorization } }
            )
            .pipe(
                tap((permissions: AuthorizationPermission[]) => this._setPermissions(permissions))
            );
    }

    private _setPermissions(permissionResponse: AuthorizationPermission[]): void {
        const appPermissions = permissionResponse.find(
            permission =>
                permission.applicationInstance === this._configService.configuration.instance
        );

        this._userInfo.roles = {};

        if (appPermissions) {
            this._permissions = appPermissions.permissions;
            this._permissions.forEach(x => {
                this._userInfo.roles[x] = true;
            });
            this._permissionsSubject.next(appPermissions.permissions);
        } else {
            this._permissions = [];
            this._permissionsSubject.next([]);
        }
    }

    private _setUser(user: AppUser): void {
        Object.assign(this._userInfo, user);
        this._userInfo.name = `${user.firstName} ${user.lastName}`;
        this._userInfo.locale = user.metadata?.Locale as Language;
    }

    public hasPermission(expectedPermissions: string[]): boolean {
        if (expectedPermissions == null) return true;

        for (const permission of expectedPermissions) {
            if (this._permissions.indexOf(permission) !== -1) return true;
        }

        return false;
    }

    public get canEditOrganization(): boolean {
        return this.hasPermission([AppPermisions.editOrganisations]);
    }

    public endImpersonation(): Observable<void> {
        return this._httpClient.delete<void>(`/impersonation/stop`, {
            headers: { type: ApiType.Authorization }
        });
    }
}
