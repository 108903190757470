import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    OnChanges
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { LgSimpleChanges } from "@logex/framework/types";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

@Component({
    selector: "app-pivot-search",
    templateUrl: "./pivot-search.component.html",
    styleUrls: ["./pivot-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PivotSearchComponent implements OnInit, OnChanges {
    @Input() isDisabled: boolean;
    @Input() initSearchText: string;
    @Input() placeholder = "Search";
    @Output() readonly search: EventEmitter<string> = new EventEmitter();

    _form: UntypedFormGroup;

    constructor(private _fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this._form = this._fb.group({
            search: new UntypedFormControl({ value: this.initSearchText, disabled: this.isDisabled })
        });

        this._searchInput.valueChanges
            .pipe(
                map(x => (x || "").toLowerCase()),
                distinctUntilChanged(),
                debounceTime(200)
            )
            .subscribe(searchText => {
                this.search.emit(searchText);
            });
    }

    ngOnChanges(changes: LgSimpleChanges<PivotSearchComponent>): void {
        if (changes.isDisabled && this._searchInput) {
            if (changes.isDisabled.currentValue) {
                this._searchInput.disable();
            } else {
                this._searchInput.enable();
            }
        }
    }

    _clearSearch(): void {
        this._searchInput.reset();
    }

    get _searchInput(): UntypedFormControl {
        return this._form?.get("search") as UntypedFormControl;
    }
}
