import { Injectable, Inject } from '@angular/core';
import { Params, Router } from '@angular/router';
import { from, Observable, throwError, Subject, of, NEVER } from 'rxjs';
import {
    catchError,
    concatMap,
    shareReplay,
    distinctUntilChanged,
    tap,
    switchMap,
    withLatestFrom,
    filter,
    map
} from 'rxjs/operators';
import createAuth0Client, {
    GetTokenSilentlyOptions,
    GetUserOptions,
    getIdTokenClaimsOptions
} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

import { Auth0Config, AUTH0_CONFIG } from './auth0-config';
import { Auth0User } from './auth0-user';

@Injectable({
    providedIn: 'root'
})
export class Auth0AuthorizationService<TUser extends Auth0User = Auth0User> {
    loggedIn: boolean = null;
    currentUserProfile: TUser | null = null;
    configured = false;

    auth0Client$: Observable<Auth0Client>;
    isAuthenticated$: Observable<boolean>;
    userProfile$: Observable<TUser>;
    authorizationError$: Observable<any>;
    newProfile$: Observable<TUser>;

    private _userProfileSubject = new Subject<TUser | null>();
    private _isAuthenticatedSubject = new Subject<boolean>();
    private _authorizationErrorSubject = new Subject<any>();
    private _config: Auth0Config;
    private _auth0Client: Promise<Auth0Client>;
    private _newToken = new Subject<string>();

    constructor(
        private _router: Router,
        @Inject(AUTH0_CONFIG) _configPromise: Promise<Auth0Config>
    ) {
        this._auth0Client = _configPromise.then(config => {
            this._config = config;
            this.configured = true;
            return createAuth0Client({
                domain: this._config.domain,
                client_id: this._config.clientId,
                redirect_uri: this._config.redirectUri || `${this.getBasePath()}/callback`,
                scope: this._config.scope,
                audience: this._config.audience
            });
        });

        // Create an observable of Auth0 instance of client
        this.auth0Client$ = from(this._auth0Client).pipe(
            shareReplay(1), // Every subscription receives the same shared value
            catchError(err => {
                this._authorizationErrorSubject.next(err);
                return throwError(err);
            })
        );

        this.isAuthenticated$ = this._isAuthenticatedSubject.pipe(
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.userProfile$ = this._userProfileSubject.pipe(shareReplay(1));

        this.isAuthenticated$.subscribe(loggedIn => {
            this.loggedIn = loggedIn;
        });

        this.userProfile$.subscribe(profile => {
            this.currentUserProfile = profile;
        });

        this.authorizationError$ = this._authorizationErrorSubject.pipe(shareReplay(5));

        this.newProfile$ = from(_configPromise).pipe(
            switchMap(config =>
                config.disableIdentityChangeCheck
                    ? NEVER
                    : this._newToken.pipe(
                          distinctUntilChanged(),
                          switchMap(token => (token === null ? of(<TUser>null) : this.getUser())),
                          catchError(() => of(<TUser>null)),
                          withLatestFrom(this._userProfileSubject),
                          filter(([newProfile, oldProfile]) => newProfile?.sub !== oldProfile?.sub),
                          map(([newProfile]) => newProfile),
                          tap(newProfile => {
                              if (newProfile === null) this._isAuthenticatedSubject.next(false);
                              this._userProfileSubject.next(newProfile);
                          })
                      )
            ),
            shareReplay(1)
        );

        this.newProfile$.subscribe();

        this._localAuthSetup();
    }

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    getUser<T = TUser>(options?: GetUserOptions): Observable<T> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getUser<T>(options)))
        );
    }

    private _tokenCache: Observable<string> = null;

    getTokenSilently(options?: GetTokenSilentlyOptions): Observable<string> {
        // There is timing issue in the auth0-spa-js code that can cause error when 2 token requests are done
        // almost in parallel, and the token is not cached yet (i.e. on bootstrap). As workaround, we
        // try to cache the value ourselves for a short while (only when there are no options specified)
        // https://community.auth0.com/t/typeerror-cannot-read-property-close-of-undefined-when-using-new-auth0-spa-library-in-angular-interceptor/28010/16
        // TODO: remove when fixed
        if (options == null && this._tokenCache !== null) {
            return this._tokenCache;
        }

        let request = this.auth0Client$.pipe(
            concatMap(client => from(client.getTokenSilently(options))),
            tap(
                token => {
                    this._newToken.next(token);
                },
                () => {
                    this._newToken.next(null);
                }
            )
        );

        if (options == null) {
            this._tokenCache = request = request.pipe(shareReplay(1));
            setTimeout(() => (this._tokenCache = null), 1000);
        }
        return request;
    }

    getIdTokenClaims(options?: getIdTokenClaimsOptions): Observable<any> {
        return this.auth0Client$.pipe(concatMap(client => from(client.getIdTokenClaims(options))));
    }

    login(redirectPath = '/', loginHint: string = undefined, queryParams?: Params): void {
        // A desired redirect path can be passed to login method
        // (e.g., from a route guard)
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log in
            client.loginWithRedirect({
                redirect_uri: this._config.redirectUri,
                login_hint: loginHint,
                appState: { target: redirectPath, queryParams }
            });
        });
    }

    logout(returnUrl?: string): void {
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log out
            client.logout({
                client_id: this._config.clientId,
                returnTo: returnUrl ?? this._config.logoutReturnToUri ?? `${this.getBasePath()}/`
            });
        });
    }

    getBasePath(): string {
        let base: string = document.baseURI;
        if (base === undefined) {
            const baseTag = document.querySelector('base');
            if (baseTag) {
                base = baseTag.href;
            } else {
                base = window.location.origin;
            }
        }
        if (base[base.length - 1] === '/') base = base.substring(0, base.length - 1);
        return base;
    }

    private async _localAuthSetup(): Promise<void> {
        const redirectInfo = await this._handleAuthCallback();
        const client = await this._auth0Client;
        let loggedIn = await client.isAuthenticated();
        if (!loggedIn && this._config.forceTicketCheck) {
            try {
                if ((await this.getTokenSilently().toPromise()) !== null) {
                    loggedIn = true;
                }
            } catch {}
        }
        this._isAuthenticatedSubject.next(loggedIn);
        try {
            if (loggedIn) {
                this._userProfileSubject.next(await client.getUser());
            } else {
                this._userProfileSubject.next(null);
            }
            if (redirectInfo.url) {
                // Redirect to target route after callback processing
                this._router.navigate([redirectInfo.url], {
                    queryParams: redirectInfo.queryParams
                });
            }
        } catch (error) {
            this._userProfileSubject.next(null);
            this._authorizationErrorSubject.next(error);
        }
    }

    private async _handleAuthCallback(): Promise<{ url: string; queryParams?: Params } | null> {
        const params = this._getQuery();

        if (params['state']) {
            if (params['error']) {
                this._authorizationErrorSubject.next(params['error']);
                return { url: 'unauthorized' };
            }
            if (!params['code']) {
                return null;
            }
        } else {
            return null;
        }
        let targetRoute: string; // Path to redirect to after login processsed
        const client = await this._auth0Client;
        try {
            const cbRes = await client.handleRedirectCallback();
            // Get and set target redirect route from callback results
            targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
            // we notify of the login early
            this._isAuthenticatedSubject.next(true);
            return { url: targetRoute, queryParams: cbRes.appState.queryParams };
        } catch (error) {
            this._authorizationErrorSubject.next(error);
            return null;
        }
    }

    private _getQuery(): Record<string, string> {
        const str = window.location.search;
        const result = {};

        str.replace(/([^?=&]+)(?:=([^&]*))?/g, (match, key, value) => {
            result[key] = value;
            return '';
        });
        return result;
    }
}
