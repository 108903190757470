import { IUser } from '@logex/framework/lg-application';
import * as _ from 'lodash';
import { Language } from '../services';
import { OrganizationInfo } from './application-types';

export class AppUser implements IUser {
    id: number;
    name: string;
    roles: _.Dictionary<boolean>;
    title: string;
    userid: string;
    ziekenhuiscode: number;
    ziekenhuisnaam: string;

    // authorization api /user/profile
    accountId: number;
    email: string;
    firstName: string;
    lastName: string;
    isDisabled: boolean;
    isInternal: boolean;
    metadata: Record<string, string>;
    locale?: Language;
    organizations: OrganizationInfo[];
    impersonation: ImpersonationInfo | null;

    constructor() {
        this.roles = {};
        this.organizations = [];
    }

    public hasPermission(role: string): boolean {
        return this.roles[role] || false;
    }
}

export interface ImpersonationInfo {
    originalUserEmail: string;
    originalUserName: string;
}
