import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@logex/framework/ui-core';
import { LgLocalizationModule } from '@logex/framework/lg-localization';
import { DropdownButtonComponent } from './components/dropdown-button/dropdown-button.component';
import { RouterModule } from '@angular/router';
import { UiToolboxModule } from '@logex/framework/ui-toolbox';
import { LgLayoutModule } from '@logex/framework/lg-layout';
import { PivotSearchComponent } from './components/pivot-search/pivot-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

@NgModule({
    declarations: [DropdownButtonComponent, PivotSearchComponent, TimeAgoPipe],
    imports: [
        CommonModule,
        RouterModule,

        UiCoreModule,
        ReactiveFormsModule,
        UiToolboxModule,
        LgLayoutModule,
        LgLocalizationModule
    ],
    exports: [
        DropdownButtonComponent,
        PivotSearchComponent,
        TimeAgoPipe,
        CommonModule,
        RouterModule,

        UiCoreModule,
        UiToolboxModule,
        LgLayoutModule,
        LgLocalizationModule
    ]
})
export class SharedModule {}
