import {
    Component,
    ViewEncapsulation,
    forwardRef,
    ElementRef,
    ChangeDetectorRef,
    Input
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ScrollDispatcher, Overlay } from '@angular/cdk/overlay';

import { LgTranslateService } from '@logex/framework/lg-localization';
import {
    LgDropdownComponent,
    LgDropdownPopupComponent,
    LgOverlayService
} from '@logex/framework/ui-core';

@Component({
    selector: 'dropdown-button',
    template: `
        <button style="display: flex;" [class]="'button ' + buttonClass">
            <lg-icon style="margin-right: .25em" [icon]="icon" [inline]="true"></lg-icon>
            {{ text }}
        </button>
    `,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownButtonComponent),
            multi: true
        }
    ]
})
export class DropdownButtonComponent<T extends number | string> extends LgDropdownComponent<T> {
    @Input() icon: string;
    @Input() text: string;
    @Input() buttonClass: string;

    _selectedIcon: string | null = null;

    constructor(
        _elementRef: ElementRef,
        _overlayService: LgOverlayService,
        _overlay: Overlay,
        _scrollDispatcher: ScrollDispatcher,
        _changeDetectorRef: ChangeDetectorRef,
        _translateService: LgTranslateService
    ) {
        super();
        this.hideSearch = true;
        this.matchWidth = false;
        this._attachOnLeft = false;
        this.popupClassName =
            'lg-dropdown-popup lg-dropdown-popup--icon-menu lg-dropdown-popup--last-highlighted';
        this._selectedIcon = this.icon;
        this.focusEnabled = false;
    }

    protected _getPopupClass(): typeof LgDropdownPopupComponent {
        return LgDropdownPopupComponent;
    }
}
