import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { LG_LOCALIZATION_SETTINGS } from '@logex/framework/lg-localization';
import { AppConfiguration } from '../types/app-configuration';
import { ConfigService } from './config.service';
import { AppLocalizationSettings } from './app-localization-settings.service';
import { Observable, of } from 'rxjs';
import { Auth0AuthorizationService } from '../auth0/auth0-authorization.service';
import { AuthorizationService } from './app-authorization.service';
import { AppUser, AuthorizationPermission } from '../types';

@Injectable({ providedIn: 'root' })
export class AppStartupService {
    constructor(
        private _httpClient: HttpClient,
        private _configService: ConfigService,
        private _auth: Auth0AuthorizationService,
        private _authorizationService: AuthorizationService,
        @Inject(LG_LOCALIZATION_SETTINGS) private _localizationSettings: AppLocalizationSettings
    ) {}

    initialize(): () => Promise<void> {
        return async () => {
            await this._loadConfig();
        };
    }

    private async _loadConfig(): Promise<{
        user: AppUser;
        permissions: AuthorizationPermission[];
    }> {
        return this._httpClient
            .get<AppConfiguration>('appsettings.json')
            .pipe(
                tap((c: AppConfiguration) => {
                    this._configService._setConfigation(c);
                }),
                switchMap(() => {
                    return this._loadPermissions();
                }),
                tap(() => this._localizationSettings.init())
            )
            .toPromise();
    }

    private _loadPermissions(): Observable<{
        user: AppUser;
        permissions: AuthorizationPermission[];
    }> {
        return this._auth.isAuthenticated$.pipe(
            first(),
            switchMap(() => this._authorizationService.loadUserData()),
            catchError(() => of({ user: null, permissions: [] }))
        );
    }
}
