import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from '../services/app-localization-settings.service';

@Injectable({
    providedIn: 'root'
})
export class LocalizationGateway {
    constructor(private _httpClient: HttpClient) {}

    setPrefferedLanguage(language: Language): Observable<any> {
        return this._httpClient.put(`/api/locale/${language}`, null);
    }
}
