import { User } from '@auth0/auth0-spa-js';

export interface Auth0User extends User {
    email: string;
    email_verified: boolean;
    name: string;
    nickname: string;
    picture: string;
    given_name: string;
    family_name: string;
    sub: string;
    updated_at: string;

    'https://mrdm.io/applications': string[];
    'https://mrdm.io/healthcareserviceproviders': string[];
}
