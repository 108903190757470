import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { LgTranslateService } from '@logex/framework/lg-localization';
@Pipe({
    name: 'lgTimeAgo',
    pure: false
})
export class TimeAgoPipe implements PipeTransform {
    constructor(private _lgt: LgTranslateService, @Inject(LOCALE_ID) private _locale: string) {}

    // Short version of TimeAgoPipe from orchestration

    transform(value: string): string {
        const d = new Date(value);
        const now = new Date();
        const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));

        const today = new Date().setHours(0, 0, 0, 0);
        const date = new Date(value).setHours(0, 0, 0, 0);
        const yesterdayDate = new Date(new Date().setHours(0, 0, 0, 0));
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);
        const yesterday = yesterdayDate.getTime();

        if (Number.isNaN(seconds)) {
            return '';
        } else if (today === date) {
            return this._lgt.translate('APP._Pipes.TimeAgo.Today');
        } else if (yesterday === date) {
            return this._lgt.translate('APP._Pipes.TimeAgo.Yesterday');
        } else {
            return new DatePipe(this._locale).transform(value, 'dd - MM - y');
        }
    }
}
