import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { Auth0AuthorizationService } from './auth0-authorization.service';

@Injectable({
    providedIn: 'root'
})
export class Auth0Guard  {
    constructor(private _auth: Auth0AuthorizationService, private _router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        return this._auth.isAuthenticated$.pipe(
            take(1),
            tap(loggedIn => {
                if (!loggedIn) {
                    this._auth.login(state.url);
                }
            })
        );
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        return this.canActivate(next, state);
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._auth.isAuthenticated$.pipe(
            take(1),
            tap(loggedIn => {
                if (!loggedIn) {
                    const queryParams =
                        this._router.getCurrentNavigation().extractedUrl.queryParams;
                    const fullPath = segments.reduce((path, currentSegment) => {
                        return `${path}/${currentSegment.path}`;
                    }, '');

                    this._auth.login(fullPath, '', queryParams);
                }
            })
        );
    }
}
