import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { mergeMap, catchError, switchMap, first } from 'rxjs/operators';
import { Auth0AuthorizationService } from './auth0-authorization.service';
import { urlConcat } from '@logex/framework/utilities';
import { ConfigService } from '../services/config.service';
import { ApiType } from '../types';

@Injectable()
export class Auth0InterceptorService implements HttpInterceptor {
    constructor(private _auth: Auth0AuthorizationService, private _config: ConfigService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this._auth.configured || this._isStaticFile(req.url)) return next.handle(req);

        let url = this._config.configuration.api.applicationUrl;

        if (req.headers.get('type') && req.headers.get('type') === ApiType.Authorization) {
            url = this._config.configuration.api.authorization;
        }

        if (req.headers.get('type') && req.headers.get('type') === ApiType.Download) {
            url = this._config.configuration.api.download;
        }

        return this._auth.isAuthenticated$.pipe(
            first(),
            switchMap(loggedIn => (loggedIn ? this._auth.getTokenSilently() : of(null))),
            catchError(() => of(null)),
            mergeMap(token => {
                const tokenReq = token
                    ? req.clone({
                          url: url ? urlConcat(url, req.url) : req.url,
                          setHeaders: { Authorization: `Bearer ${token}` }
                      })
                    : req;
                return next.handle(tokenReq);
            }),
            catchError(err => throwError(err))
        );
    }

    private _isStaticFile(url: string): boolean {
        return url.endsWith('.json');
    }
}
