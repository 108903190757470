export enum ApplicationDisplayMode {
    Cards,
    List
}

export class ApplicationGroup {
    id: number;
    code: string;
    displayName: string;
    applications: Application[];
}

export class Application {
    code: string;
    groupCode: string;
    displayName: string;
    description: string;
    documentationUrl: string;
    logoUrl: string;
    applicationInstances: ApplicationInstance[];
}

export class ApplicationInstance {
    code: string;
    displayName: string;
    description: string;
    url: string;
    isDisabled: boolean;
    userHasAccess: boolean;
}

export class OrganizationInfo {
    cicCode: number;
    organizationId: number;
    mrdmUri: string;
    name: string;
    position: string;
    agbCode: string;
}
